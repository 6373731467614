import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-center">
      <h1 className="text-4xl font-bold">404 - Page non trouvée</h1>
      <p className="text-lg mt-4">La page que vous recherchez n'existe pas.</p>
      <Link to="/" className="mt-6 px-4 py-2 bg-blue-600 text-white rounded">
        Retour à l'accueil
      </Link>
    </div>
  );
}

export default NotFound;
