import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

const Post = ({ images, title, description, date }) => {
  const [mainImage, setMainImage] = useState(images[0]);

  // Clé unique pour le compteur de j'aime
  const storageKey = `likeCount-${title}-${date}`;
  const [likeCount, setLikeCount] = useState(() => {
    const stored = localStorage.getItem(storageKey);
    return stored ? parseInt(stored, 10) : 0;
  });

  useEffect(() => {
    images.forEach((imageSrc) => {
      const img = new Image();
      img.src = imageSrc;
    });
  }, [images]);

  const swapImage = (clickedImage) => {
    setMainImage(clickedImage);
  };

  const handleLike = () => {
    const newCount = likeCount + 1;
    setLikeCount(newCount);
    localStorage.setItem(storageKey, newCount);
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          text: description,
          url: window.location.href,
        });
        console.log("Partagé avec succès !");
      } catch (error) {
        console.error("Erreur lors du partage :", error);
      }
    } else {
      // Fallback: copier l'URL dans le presse-papiers
      try {
        await navigator.clipboard.writeText(window.location.href);
        alert("URL copiée dans le presse-papiers !");
      } catch (error) {
        alert("Le partage n'est pas supporté par votre navigateur.");
      }
    }
  };

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      {/* En-tête du post */}
      <div className="flex items-center p-4">
        <img
          src="/images/logosfrenovation.webp"
          alt="Avatar SF Rénovation"
          className="w-12 h-12 rounded-full mr-3"
        />
        <div>
          <p className="font-semibold text-gray-800">SF Rénovation</p>
          <p className="text-xs text-gray-500">
            {new Date(date).toLocaleDateString()}
          </p>
        </div>
      </div>

      {/* Contenu textuel */}
      <div className="px-4 pb-4">
        <p className="mb-2 text-gray-800 font-medium">{title}</p>
        <p className="mb-4 text-gray-700">{description}</p>
      </div>

      {/* Zone d'images */}
      {images && images.length > 0 && (
        <div>
          <img
            src={mainImage}
            alt="Contenu du post"
            className="w-full h-96 sm:h-[500px] object-contain"
          />
          {images.length > 1 && (
            <div className="flex space-x-2 p-4 overflow-x-auto">
              {images
                .filter((img) => img !== mainImage)
                .map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Vignette ${index + 1}`}
                    className="w-16 h-16 object-cover rounded cursor-pointer border"
                    onClick={() => swapImage(image)}
                  />
                ))}
            </div>
          )}
        </div>
      )}

      {/* Barre d'actions */}
      <div className="border-t flex justify-around text-gray-600">
        <div
          className="hover:bg-gray-100 flex-1 text-center py-2 cursor-pointer"
          onClick={handleLike}
        >
          J'aime {likeCount > 0 && `(${likeCount})`}
        </div>
        <div
          className="hover:bg-gray-100 flex-1 text-center py-2 cursor-pointer"
          onClick={handleShare}
        >
          Partager
        </div>
      </div>
    </div>
  );
};

const Projects = () => {
  const postsData = [
    {
      title:
        "Nouvelle réalisation avant cette période de fêtes de fin d’années !",
      description:
        "Rénovation salle de bain complète avec pose d’un parquet vinyle. Un carrelage marbre beige 120/60. Montage des meubles et pose des éléments de salle de bain.",
      date: "2024-12-18",
      images: [
        "../images/post1-1.webp",
        "../images/post1-2.webp",
        "../images/post1-3.webp",
        "../images/post1-4.webp",
        "../images/post1-5.webp",
      ],
    },
    {
      title: "Cimentage extérieur",
      description: "Client très satisfait du résultat. Voici les photos.",
      date: "2024-07-24",
      images: [
        "../images/post4-1.webp",
        "../images/post4-2.webp",
        "../images/post4-3.webp",
        "../images/post4-4.webp",
      ],
    },
    {
      title: "Embellissement d’une cuisine",
      description:
        "Pose de crédence, pose d’une plaque vitrée en dessous de là hôte, modification des prises électriques, installation de levier +plomberie, réalisation d’un nouveau plan de travail.",
      date: "2024-12-01",
      images: [
        "../images/post2-1.webp",
        "../images/post2-2.webp",
        "../images/post2-3.webp",
        "../images/post2-4.webp",
      ],
    },
    {
      title: "Rénovation Toilette",
      description:
        "Carrelage effet parquet au sol, un carrelage mural en 20x60. Installation d’un toilette + un lave main, modification de la plomberie et la pose du luminaire.",
      date: "2024-12-01",
      images: [
        "../images/post3-1.webp",
        "../images/post3-2.webp",
        "../images/post3-3.webp",
      ],
    },
    {
      title: "Rénovation Salle de bain",
      description:
        "Démontage des anciens meubles, démolition de la cloison de douche, placo, isolation, enduit, peinture, carrelage, montage des meubles et éléments de salle de bain.",
      date: "2025-01-25",
      images: [
        "../images/post5-1.webp",
        "../images/post5-2.webp",
        "../images/post5-3.webp",
        "../images/post5-4.webp",
        "../images/post5-5.webp",
        "../images/post5-6.webp",
        "../images/post5-7.webp",
      ],
    },
  ];

  const sortedPosts = postsData.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  return (
    <div className="bg-gray-100 min-h-screen p-4">
      <Helmet>
        <title>Prestations - SF Rénovation</title>
        <meta
          name="description"
          content="Retrouvez en images, mon expertise sur mes dernières prestations."
        />
        <meta
          name="keywords"
          content="projets, images, posts, prestations, SF Rénovation"
        />
        <meta name="author" content="SF Rénovation" />
      </Helmet>
      {/* Sur mobile (écran xs), 1 colonne, sur les écrans moyens (sm) et plus, 2 colonnes */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {sortedPosts.map((post, index) => (
          <Post
            key={index}
            title={post.title}
            description={post.description}
            date={post.date}
            images={post.images}
          />
        ))}
      </div>
    </div>
  );
};

export default Projects;
