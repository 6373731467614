import React from "react";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div className="bg-gray-50 min-h-screen py-16">
      {/* SEO Meta Tags */}
      <Helmet>
        <title>A propos - SF Rénovation</title>
        <meta
          name="description"
          content="SF Rénovation, spécialiste de la rénovation intérieure et extérieure dans les Hauts-de-France. Nous transformons vos espaces pour qu'ils correspondent parfaitement à vos attentes."
        />
        <meta
          name="keywords"
          content="SF Rénovation, maçonnerie, artisan, renovation, a propos, salle de bain, carrelage, placo, Nord, Lille, Wattrelos, Hauts-de-France"
        />
        <meta name="author" content="SF Rénovation" />
      </Helmet>
      <div className="container mx-auto px-6 lg:px-16">
        {/* Section titre */}
        <div className="text-center mb-12">
          <h1 className="text-5xl font-extrabold text-gray-900">
            À propos de SF Rénovation - Expert de la rénovation
          </h1>
          <p className="text-lg text-gray-600 mt-4 max-w-3xl mx-auto">
            Découvrez notre entreprise et notre engagement à fournir des
            services de qualité.
          </p>
        </div>

        {/* Section contenu principal */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 mb-20 items-center">
          {/* Texte principal */}
          <div>
            <h2 className="text-3xl font-bold text-gray-900 mb-6">
              L'entreprise
            </h2>
            <p className="text-gray-700 leading-relaxed">
              SF Rénovation est une entreprise spécialisée dans la rénovation
              intérieure et les travaux de maçonnerie, dirigée par un artisan
              passionné et expérimenté. Fort d’un savoir-faire diversifié, je
              réalise des prestations sur mesure dans la rénovation de salles de
              bains, la pose de carrelage, le placo, les enduits ainsi que les
              travaux de petit et gros œuvre. Mon objectif est de vous offrir
              des solutions adaptées pour optimiser vos espaces, rénover vos
              intérieurs ou concrétiser vos projets de construction, avec une
              attention particulière portée aux finitions et aux matériaux
              utilisés.
            </p>
            <p className="text-gray-700 leading-relaxed mt-6">
              En tant qu’artisan indépendant, je vous accompagne à chaque étape,
              de la conception à la réalisation, en garantissant un service
              personnalisé, fiable et de qualité. Chaque chantier est pour moi
              l’occasion de mettre mon expertise au service de vos besoins, avec
              le souci constant du détail, du respect des délais et de vos
              attentes. SF Rénovation, c’est la promesse d’un travail soigné,
              réalisé avec passion pour valoriser votre habitation et vous
              offrir un résultat durable et esthétique.
            </p>
          </div>

          {/* Image ou visuel */}
          <div className="w-full h-80 flex items-center justify-center overflow-hidden rounded-lg">
            <img
              src="images/logosfrenovation.webp"
              alt="Notre entreprise"
              className="object-contain w-2/3 h-auto animate-fade-in"
            />
          </div>
        </div>

        {/* Section valeurs et mission */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-8">
            Nos valeurs et notre mission
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              { title: "Engagement", text: "Nous respectons nos promesses." },
              { title: "Qualité", text: "Des services irréprochables." },
              { title: "Innovation", text: "Toujours à la pointe." },
            ].map((value, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
              >
                <h3 className="text-lg font-semibold text-gray-900 mb-3">
                  {value.title}
                </h3>
                <p className="text-gray-600">{value.text}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Section équipe */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-8">
            Notre équipe
          </h2>
          <p className="text-gray-600 text-center max-w-3xl mx-auto mb-8">
            Rencontrez les personnes talentueuses derrière notre entreprise.
          </p>
          <div className="flex justify-center">
            <div className="text-center hover:scale-105 transition-transform duration-300">
              <img
                src="/images/fabien.webp"
                alt="Fabien Spada"
                className="w-56 h-56 mx-auto rounded-full mb-4 object-cover shadow-md"
              />
              <h3 className="text-lg font-bold text-gray-900">Fabien Spada</h3>
              <p className="text-gray-600">PDG</p>
            </div>
          </div>
        </div>

        {/* Section carte Google */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-8">
            Nous trouver
          </h2>
          <div className="w-full h-96 bg-gray-200 rounded-lg shadow-lg overflow-hidden">
            <iframe
              title="google map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2526.670343890794!2d3.198561444375628!3d50.70750173300164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c328a622e1e3a7%3A0x27fcd877a17b5409!2s81%20Rue%20du%2019%20Mars%201962%2C%2059150%20Wattrelos!5e0!3m2!1sfr!2sfr!4v1733430474753!5m2!1sfr!2sfr"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
