import React from "react";
import { motion } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";

const ExteriorDetails = () => {
  const images = [
    "../images/exterior1.webp",
    "../images/exterior2.webp",
    "../images/exterior3.webp",
    "../images/exterior4.webp",
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Par défaut, 2 images visibles sur les grands écrans
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    pauseOnHover: true,
    centerMode: true,
    centerPadding: "0px", // Suppression des espaces inutiles
    responsive: [
      {
        breakpoint: 1024, // Pour les tablettes
        settings: {
          slidesToShow: 1, // 1 image visible
        },
      },
      {
        breakpoint: 640, // Pour les mobiles
        settings: {
          slidesToShow: 1, // 1 image visible
        },
      },
    ],
  };

  return (
    <motion.section
      className="py-20 bg-gradient-to-bl from-gray-100 via-gray-50 to-white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="container mx-auto px-6 lg:px-20 text-center">
        {/* SEO Meta Tags */}
        <Helmet>
          <title>Rénovation extérieures - SF Rénovation</title>
          <meta
            name="description"
            content="Retrouver en détails mes prestations pour vos rénovations extérieures."
          />
          <meta
            name="keywords"
            content="rénovation intérieure, Hauts-de-France, Nord, Enduits, Revêtements, Finitions, Moderne, Design, SF Rénovation"
          />
          <meta name="author" content="SF Rénovation" />
        </Helmet>
        {/* Titre principal */}
        <motion.div
          className="mb-12"
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="text-4xl lg:text-5xl font-extrabold text-gray-900 leading-snug mb-4">
            Rénovations et Finitions Extérieures
          </h1>
          <p className="text-lg text-gray-700 max-w-2xl mx-auto">
            Sublimez vos façades et extérieurs avec des matériaux durables, un
            design moderne, et un savoir-faire de qualité pour transformer votre
            espace extérieur en un lieu unique.
          </p>
        </motion.div>

        {/* Carrousel d'images */}
        <motion.div
          className="mb-16"
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <div className="max-w-screen-md mx-auto">
            {" "}
            {/* Limite la largeur */}
            <Slider {...sliderSettings}>
              {images.map((src, index) => (
                <div key={index} className="px-2">
                  <div
                    className="relative w-full overflow-hidden rounded-xl shadow-2xl"
                    style={{
                      paddingTop: "75%", // Maintien du ratio 4:3 (75% est équivalent à 4:3)
                    }}
                  >
                    <img
                      src={src}
                      alt={`Exterior example ${index + 1}`}
                      className="absolute top-0 left-0 w-full h-full object-cover"
                      loading="lazy"
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </motion.div>

        {/* Liste des services */}
        <motion.div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8"
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <div className="bg-white shadow-lg rounded-2xl p-6 text-left hover:shadow-2xl transition-all duration-300 ease-in-out">
            <h1 className="text-xl font-semibold text-gray-800 mb-4">
              Enduits Protecteurs
            </h1>
            <p className="text-gray-600">
              Protégez vos murs extérieurs contre les intempéries grâce à des
              solutions innovantes et durables qui prolongeront la durée de vie
              de vos surfaces.
            </p>
          </div>
          <div className="bg-white shadow-lg rounded-2xl p-6 text-left hover:shadow-2xl transition-all duration-300 ease-in-out">
            <h1 className="text-xl font-semibold text-gray-800 mb-4">
              Revêtements Résistants
            </h1>
            <p className="text-gray-600">
              Offrez à vos extérieurs une protection maximale tout en préservant
              l'esthétique avec des revêtements résistants et élégants adaptés à
              toutes les conditions climatiques.
            </p>
          </div>
          <div className="bg-white shadow-lg rounded-2xl p-6 text-left hover:shadow-2xl transition-all duration-300 ease-in-out">
            <h1 className="text-xl font-semibold text-gray-800 mb-4">
              Finitions Esthétiques
            </h1>
            <p className="text-gray-600">
              Apportez une touche d’élégance à votre propriété grâce à des
              finitions soignées et modernes, créant un espace extérieur
              harmonieux et stylé.
            </p>
          </div>
        </motion.div>

        {/* Appel à l'action */}
        <motion.div
          className="mt-16"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <p className="text-gray-700 text-lg max-w-xl mx-auto mb-8">
            Nous allions design et robustesse pour des extérieurs qui vous
            ressemblent. Parlons de votre projet et donnons vie à vos idées.
          </p>
          <a
            href="/contact"
            className="px-8 py-4 bg-yellow-500 text-white font-bold text-lg rounded-full shadow-md hover:bg-yellow-600 transition-all duration-300 ease-in-out"
          >
            Obtenir un devis
          </a>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default ExteriorDetails;
